import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { dashboardStore, alunoStore } from '@/store';
import ShowPopup from '@/components/ShowPopup.vue';
import _ from 'lodash';
import OcorrenciaComponent from '@/components/OcorrenciaComponent.vue';
import PedagogicoAlunoComponent from '@/components/PedagogicoAlunoComponent.vue';
import FinanceiroAlunoComponent from '@/components/FinanceiroAlunoComponent.vue';
import ClickableCard from '@/components/ClickableCard.vue';
import EntidadeLinkComponent from '@/components/EntidadeLinkComponent.vue';
import ContratoChipComponent from '@/components/ContratoChipComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
let Retencao = class Retencao extends Vue {
    constructor() {
        super(...arguments);
        // @ts-ignore
        this.loading = true;
        this.propertyToShowDetails = [];
        this.retencaoHeaders = [
            { text: 'Aluno', value: 'aluno_nome' },
            { text: 'Contratos', value: 'contratos' },
            { text: 'Retenção', value: 'retencao' },
            {
                text: 'Quantidade de parcelas vencidas',
                value: 'quantidade_parcelas_atraso',
            },
            {
                text: 'Quantidade de faltas consecutivas',
                value: 'quantidade_faltas_consecutivas',
            },
            {
                text: 'Quantidade de parcelas protestadas',
                value: 'quantidade_parcelas_protestadas',
            },
            { text: 'Contrato assinado em', value: 'vigencia_inicio' },
            { text: 'Contatos', value: 'ultimo_contato' },
            { text: 'Ações', value: 'actions' },
        ];
        this.selectedContrato = null;
        this.search = '';
        this.aluno = null;
        this.retencaoAdministrativaQtd = 0;
        this.retencaoOperacionalQtd = 0;
        this.retencaoComercialQtd = 0;
        this.retencaoFinanceiraQtd = 0;
        this.retencaoPedagogicaQtd = 0;
        this.financeirosProtestadosQtd = 0;
        this.panelOpen = false;
    }
    get dashboardReposicao() {
        return dashboardStore.dashboardReposicao;
    }
    get retencaoItemsFiltered() {
        const retencao_administrativa = this.propertyToShowDetails.includes('Retenção Administrativa');
        const retancao_comercial = this.propertyToShowDetails.includes('Retenção Comercial');
        const retencao_financeira = this.propertyToShowDetails.includes('Retenção Financeira');
        const retencao_pedagogica = this.propertyToShowDetails.includes('Retenção Pedagógica');
        const retencao_operacional = this.propertyToShowDetails.includes('Retenção Operacional');
        const financeiros_protestados = this.propertyToShowDetails.includes('Financeiros protestados');
        // const items = dashboardStore.dashboardRetencao;
        // return items;
        this.retencaoAdministrativaQtd = 0;
        this.retencaoComercialQtd = 0;
        this.retencaoFinanceiraQtd = 0;
        this.retencaoPedagogicaQtd = 0;
        this.retencaoOperacionalQtd = 0;
        this.financeirosProtestadosQtd = 0;
        const arr = [];
        // @ts-ignore
        _.forEach(dashboardStore.dashboardRetencao, (item) => {
            let addItem = false;
            // @ts-ignore
            if (item.retencao.includes('Administrativa')) {
                this.retencaoAdministrativaQtd++;
                if (retencao_administrativa) {
                    addItem = true;
                }
            }
            // @ts-ignore
            if (item.retencao.includes('Comercial')) {
                this.retencaoComercialQtd++;
                if (retancao_comercial) {
                    addItem = true;
                }
            }
            // @ts-ignore
            if (item.retencao.includes('Financeira')) {
                this.retencaoFinanceiraQtd++;
                if (retencao_financeira) {
                    addItem = true;
                }
            }
            // @ts-ignore
            if (item.retencao.includes('Pedagógica')) {
                this.retencaoPedagogicaQtd++;
                if (retencao_pedagogica) {
                    addItem = true;
                }
            }
            // @ts-ignore
            if (item.retencao.includes('Operacional')) {
                this.retencaoOperacionalQtd++;
                if (retencao_operacional) {
                    addItem = true;
                }
            }
            // @ts-ignore
            if (item.retencao.includes('Protestado')) {
                this.financeirosProtestadosQtd++;
                if (financeiros_protestados) {
                    addItem = true;
                }
            }
            if (addItem) {
                arr.push(item);
            }
        });
        return arr;
    }
    async showDetails(item) {
        const pedagogico = await alunoStore.getPedagogico(parseInt(item.aluno_id, 0));
    }
    async showAluno(item) {
        this.selectedContrato = null;
        this.selectedContrato = item;
        const form = this.$refs.showAluno;
        this.aluno = await alunoStore.getAluno(parseInt(this.selectedContrato.aluno_id, 0));
        form.show();
    }
    async carregaDados() {
        if (!this.panelOpen) {
            this.loading = true;
            this.propertyToShowDetails = [
                'Retenção Administrativa',
                'Retenção Comercial',
                'Retenção Financeira',
                'Retenção Pedagógica',
                'Retenção Operacional',
                'Financeiros protestados',
            ];
            await dashboardStore.getDashboardRetencao();
            this.loading = false;
            this.panelOpen = true;
        }
        else {
            this.panelOpen = false;
        }
    }
    async mounted() {
        await this.carregaDados();
    }
};
Retencao = __decorate([
    Component({
        components: {
            ClickableCard,
            ContratoChipComponent,
            EntidadeLinkComponent,
            FinanceiroAlunoComponent,
            OcorrenciaComponent,
            PedagogicoAlunoComponent,
            ShowPopup,
            TextExpandableComponent,
        },
    })
], Retencao);
export default Retencao;
